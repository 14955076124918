import DefaultFormFields from '@entity/EntityForm/Fields';

import JsonField from '@/entities/status/JsonField';

// We can import additional Components here to expand or overwrite Boilerplate Components
const FormFields = Object.assign(DefaultFormFields, {
  JsonField,
});

export default FormFields;
