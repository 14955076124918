import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EntityFormPage, { EntityForm } from '@entity/EntityForm/EntityForm';
import getGraphqlData from '@boilerplate/lib/getGraphqlData';
import { BaseFormInsertProps } from '@boilerplate/types/entity';
import { addEntityToRelatedDataOptions } from '@boilerplate/lib/relatedDataToOptions';
import Notistack from '@/lib/notistack';
import { catchGraphQlError } from '@/lib/catchError';

import UserEntity from '@/entities/user';
  import UserTenantRoleEntity from '@/entities/userTenantRole';

function UserBaseInsertForm({ formOnly = false, onClose, Entity = UserEntity, ...props }: BaseFormInsertProps<typeof UserEntity.model>) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createMutation, { loading }] = UserEntity.model.useCreate();

  const Component = formOnly ? EntityForm : EntityFormPage;

  const relationsOptionsQuery = UserEntity.model.useRelationsOptions({
    variables: {
    }
  });

  const relationsOptions = useMemo(
    () =>
      addEntityToRelatedDataOptions(relationsOptionsQuery, [
      ]),
    [relationsOptionsQuery]
  );

  const handleSubmit = (data) => {
    data.variables = getGraphqlData(Entity, data.variables, 'insert');

    createMutation(data)
      .then((result) => {
        Notistack.toast(t('crud:createdItem', { item: t('entities:user') }), { variant: 'success' });

        if (data.variables?.invite) {
          Notistack.toast(t('users:invite.success'), { variant: 'success' });
        }

        if (!formOnly) {
          navigate(-1);
        }

        return onClose?.(result.data?.createUser);
      })
      .catch(catchGraphQlError);
  };

  if (relationsOptionsQuery.loading) {
    return <CircularProgress />;
  }

  return (
    <Component
      {...props}
      type="insert"
      Entity={Entity}
      loading={loading}
      handleSubmit={handleSubmit}
      relationsOptions={relationsOptions}
    />
  );
}

export default UserBaseInsertForm;
