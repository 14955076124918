import { Code as CodeIcon } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleSchema from 'simpl-schema';
import { makeStyles } from 'tss-react/mui';
import { useField, FieldProps, filterDOMProps } from 'uniforms';

const useStyles = makeStyles()(({ spacing }) => ({
  textField: {
    fontFamily: 'monospace',
  },
  button: {
    marginTop: spacing(1),
    marginLeft: 'auto',
    display: 'flex',
  },
}));

const isValid = (value: string) => {
  try {
    const parsedValue = JSON.parse(value);

    if (parsedValue && typeof parsedValue === 'object') {
      return true;
    }
  } catch {}

  return false;
};

function JsonField(props: FieldProps<string, unknown>): JSX.Element {
  // eslint-disable-next-line react/destructuring-assignment
  const [field, ctx] = useField(props.name, props);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleFormat = () => {
    if (!field.value) {
      return;
    }

    try {
      const parsedValue = JSON.parse(field.value);

      field.onChange(JSON.stringify(parsedValue, null, 4));
    } catch {}
  };

  const hasError = useMemo(() => !!field.error || (!!field.value && !isValid(field.value)), [field.error, field.value]);

  useEffect(() => {
    const validator = (doc: typeof ctx.model) => {
      const value = doc[field.name];

      if (!isValid(value)) {
        return [{ name: field.name, type: SimpleSchema.ErrorTypes.VALUE_NOT_ALLOWED, value }];
      }

      return [];
    };

    // @ts-expect-error: Missing types
    const schema = ctx.schema.schema;

    schema.addDocValidator(validator);

    return () => {
      schema._docValidators = schema._docValidators.filter((func: (...a: unknown[]) => void) => func !== validator);
    };
  }, [ctx, field.name]);

  return (
    <div>
      <TextField
        {...filterDOMProps(field)}
        inputProps={{ className: classes.textField, spellCheck: 'false' }}
        onChange={(e) => field.onChange(e.target.value)}
        label={field.label}
        value={field.value}
        error={hasError}
        multiline
        fullWidth
      />
      <Button startIcon={<CodeIcon />} className={classes.button} onClick={handleFormat} variant="outlined" size="small">
        {t('crud:format')}
      </Button>
    </div>
  );
}

export default JsonField;
