import PageCard from '@boilerplate/components/PageCard';
import React from 'react';

import { useTenantifiedUserStore } from '@/stores/UserStore';

export default function LandingPage() {
  const user = useTenantifiedUserStore();

  return (
    <PageCard heading="Welcome">
      <p>This is your LandingPage</p>
      <p>Tenant: {user.currentTenant.name}</p>
    </PageCard>
  );
}
